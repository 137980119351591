import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/ocean-ereferral-network-hero.jpg';
import TwoCol from '../components/rows/twocol/index';
import OneCol from '../components/rows/onecol';

import Follow from '../components/rows/cta/follow';

export default function HealthcareProviders({ data }) {
  const hcpCol = data.strapiHealthcareProviders.hcpTwocol;

  return (
    <Layout>
      <Seo
        title="Healthcare Providers"
        description="Access your Ocean eReferral information here."
      />
      <Hero title="Healthcare Providers" banner={Banner} />
      <TwoCol
        title={hcpCol.title}
        description={hcpCol.description}
        link={hcpCol.button_title}
        href={hcpCol.button_link}
        image={hcpCol.image.localFile.publicURL}
      />
      <div className="mb-20" />
      <OneCol
        blueBG
        pdf
        newTab
        title="Need to make a referral to PACE? Find our forms here"
        link="Patient Referral Form"
        href="/pace-patient-referral-form.pdf"
        target="_blank"
        link2="Orillia ECHO Referral"
        href2="/pace-cardiology-orillia-referral-form-2021.pdf"
      />
      <div className="mt-56">
        <Follow />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query HCP {
    strapiHealthcareProviders {
      hcpTwocol {
        id
        title
        description
        button_link
        button_title
        image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
